//
// Off-Canvas
// --------------------------------------------------

// Static / Trigered Container
.offcanvas-container.is-static,
.offcanvas-container.is-triggered {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: $offcanvas-width;
  height: 100%;
  padding: {
    top: floor($spacer * 2.625);
    bottom: ($spacer * 1.5);
  }
  transition: left .4s cubic-bezier(.785, .135, .15, .86) .3s, box-shadow .3s .3s;
  background-color: $offcanvas-bg;
  box-shadow: $offcanvas-box-shadow;
  z-index: $zindex-offcanvas;
  & + .offcanvas-wrapper { padding-left: ($offcanvas-width + 15); }
  &.offcanvas-container-reverse {
    right: 0;
    left: auto;
    transition: right .4s cubic-bezier(.785, .135, .15, .86) .3s, box-shadow .3s .3s;
    box-shadow: $offcanvas-box-shadow-reverse;
    & + .offcanvas-wrapper {
      padding: {
        right: ($offcanvas-width + 15);
        left: 0;
      }
    }
  }
  .offcanvas-scrollable-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { display: none; }
  }
}
.offcanvas-container.is-triggered {
  top: 0;
  left: -($offcanvas-width);
  height: 100%;
  box-shadow: none;
  &.in-view {
    left: 0;
    box-shadow: $offcanvas-box-shadow;
  }
  &.offcanvas-container-reverse {
    right: -($offcanvas-width);
    left: auto;
    box-shadow: none;
    &.in-view {
      right: 0;
      box-shadow: $offcanvas-box-shadow-reverse;
    }
  }
  .offcanvas-close { display: block; }
}
@media (max-width: 1480px) {
  .offcanvas-container.is-static {
    width: ($offcanvas-width - 50);
    & + .offcanvas-wrapper {
      padding-left: (($offcanvas-width + 15) - 50);
    }
    &.offcanvas-container-reverse + .offcanvas-wrapper {
      padding: {
        right: (($offcanvas-width + 15) - 50);
        left: 0;
      }
    }
  }
}

// Offcanvas Footer
.offcanvas-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $border-color;
  background-color: $white;
}

// Toggle
.offcanvas-toggle {
  display: none;
  position: fixed;
  top: 50%;
  left: 0;
  width: ($btn-height + 3);
  height: $btn-height;
  margin-top: -($btn-height / 2);
  padding-right: .1875rem;
  transition: color .3s;
  background-color: $white;
  color: $body-color;
  font-size: ($font-size-base * 1.375);
  line-height: $btn-height;
  box-shadow: $offcanva-toggle-box-shadow;
  cursor: pointer;
  z-index: $zindex-offcanvas-toggle;
  text: {
    align: center;
    decoration: none;
  }
  > i {
    position: relative;
    z-index: 5;
  }
  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: .1875rem;
    height: 100%;
    transition: width .25s;
    background-color: $primary;
    content: '';
    z-index: 1;
  }
  &:hover {
    color: $white;
    &::after { width: 100%; }
  }
  &.offcanvas-toggle-reverse {
    right: 0;
    left: auto;
    padding: {
      right: 0;
      left: .1875rem;
    }
    &::after {
      right: auto;
      left: 0;
    }
  }
}

// Off-Canvas Close
.offcanvas-close {
  display: none;
  position: absolute;
  top: .5rem;
  right: .8125rem;
  color: $gray-700;
  font-size: ($font-size-base * 1.375);
  cursor: pointer;
}

// List group inside off-canvas
.offcanvas-scrollable-area {
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: $border-width solid $border-color;
  }
}

@include media-breakpoint-down(md) {
  .offcanvas-toggle,
  .offcanvas-close { display: block; }
  .offcanvas-container,
  .offcanvas-container.is-static,
  .offcanvas-container.is-triggered {
    display: block;
    position: fixed;
    top: 0;
    left: -($offcanvas-width);
    width: $offcanvas-width;
    height: 100%;
    padding: {
      top: floor($spacer * 2.625);
      bottom: ($spacer * 1.5);
    }
    transition: left .4s cubic-bezier(.785, .135, .15, .86) .3s, box-shadow .3s .3s;
    background-color: $white;
    z-index: 1100;
    overflow-y: auto;
    &.in-view {
      left: 0;
      box-shadow: $offcanvas-box-shadow;
    }
    &.offcanvas-container-reverse {
      right: -($offcanvas-width);
      left: auto;
      transition: right .4s cubic-bezier(.785, .135, .15, .86) .3s, box-shadow .3s .3s;
      &.in-view {
        right: 0;
        box-shadow: $offcanvas-box-shadow-reverse;
      }
    }
    & + .offcanvas-wrapper {
      padding: {
        right: 0 !important;
        left: 0 !important;
      }
    }
    .offcanvas-scrollable-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar { display: none; }
    }
  }
  .page-title.page-title-indent-left,
  .page-title.page-title-indent-right {
    padding: {
      right: 0 !important;
      left: 0 !important;
    }
  }
}
